<template>
  <v-container>
    <v-row>
      <v-col>
        <v-form v-model="validMsg" ref="msgForm" @submit="msg_submit">
          <v-card>
            <v-card-title>Mensajes </v-card-title>
            <v-card-text>
              <v-col cols="8">
                <v-text-field
                  v-model="broadcast.campaign"
                  hide-details="auto"
                  label="Campaña"
                  :rules="f_required"
                  outlined
                  required
                  dense
                />
              </v-col>
              <v-row class="mt-6">
                <v-col cols="8">
                  <v-text-field
                    v-model="broadcast.email"
                    hide-details="auto"
                    label="Email"
                    outlined
                    required
                    dense
                  />
                </v-col>
                <v-col cols="4">
                  <v-btn @click="broadcast_dialog = !broadcast_dialog"
                    >Destinatarios</v-btn
                  >
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="subject"
                    hide-details="auto"
                    label="Asunto"
                    outlined
                    required
                    dense
                    :rules="f_required"
                  />
                </v-col>
                <v-col cols="12">
                  <v-img contain aspect-ratio="1" class="grey lighten-5" :src="msgheader" max-height="250" />
                </v-col>
                <v-col cols="12" class="vueEditorCSS">
                  <vue-editor v-model="msgbody"></vue-editor>
                </v-col>
                <v-col cols="12">
                  <v-file-input
                    v-model="chosenPhoto"
                    counter
                    @input="PhotoToBase64"
                    label="Foto"
                    placeholder="Seleccione una imagen"
                    append-outer-icon="mdi-upload"
                    @click:append-outer="PhotoToBase64"
                    outlined
                    :show-size="1000"
                  >
                    <template v-slot:selection="{ index, text }">
                      <v-chip v-if="index < 2" label small>
                        {{ text }}
                      </v-chip>

                      <span
                        v-else-if="index === 2"
                        class="text-overline grey--text text--darken-3 mx-2"
                      >
                        +{{ chosenFile.length - 2 }} File(s)
                      </span>
                    </template>
                  </v-file-input>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn type="submit">
                <span>Enviar</span>
                <v-icon>mdi-send</v-icon>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-col>
      <v-col>
        <v-card height="100%">
          <v-card-title> Log </v-card-title>
          <v-card-text>
            <v-data-table
              :headers="headers_log"
              :items="mailer_log"
              item-key="issuedate"
              mobile-breakpoint="0"
              sort-by="issuedate"
              class="elevation-1"
              :loading="loading_status"
              loader-height="2"
              dense
              loading-text="Cargando ..."
            >
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <PartyForm
      :table="table"
      :item="item"
      :dialog="dialog"
      :newItem="newItem"
      @close="dialog = false"
      @refresh="refresh()"
      @newOrder="newOrder(item)"
    />

    <v-dialog v-model="broadcast_dialog" max-width="80%">
      <v-data-table
        v-model="selected"
        :headers="headers[$vuetify.breakpoint.xsOnly]"
        :items="items"
        :items-per-page="10"
        item-key="code"
        sort-by="issue_date"
        mobile-breakpoint="10"
        :search="search"
        :loading="loading_status"
        loader-height="10"
        loading-text="Cargando ..."
        @click:row="handleClick"
        show-select
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>Clientes</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click="SendEmails">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-toolbar>
          <v-text-field v-model="search" label="Buscar" class="mx-4" />
        </template>
        <template v-slot:[`item.name`]="{ item }">
          {{ item.name }} <br />
          <small>{{ item.address }}</small>
          <span v-if="$vuetify.breakpoint.xsOnly">
            <br />
            <small>{{ item.issue_date }}</small>
          </span>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon small class="mr-2" @click="handleClick(item)">
            mdi-account-edit-outline
          </v-icon>

          <v-icon small class="mr-2" @click="newOrder(item)">
            mdi-file-document-plus-outline
          </v-icon>
        </template>
      </v-data-table>
    </v-dialog>

    <v-dialog v-model="loading_status" hide-overlay persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          Cargando ...
          <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { webserver, getIssueDate, getToday } from "../services/webserver.js";
import createItem from "../utils/createParty.js";
import PartyForm from "../components/ClientForm.vue";
import { VueEditor } from "vue2-editor";


export default {
  components: { PartyForm, VueEditor },
  mounted() {
    this.get_items();
  },
  data() {
    return {
      msgheader: null,
      msgbody:null,
      selected: [],
      subject: null,
      chosenPhoto: null,
      table: "clients",
      validMsg: false,
      broadcast: { email: null, subject: null, msg: null },
      broadcast_dialog: false,
      f_required: [(v) => !!v || "Requerido"],

      dialog: false,
      newItem: false,
      search: "",
      loading_status: false,
      errors: [],
      items: [],
      item: createItem(),
      headers_log: [
        {
          text: "Fecha/Hora",
          align: "start",
          sortable: true,
          value: "issuedate",
          dataType: "text",
        },
        {
          text: "Campaña",
          align: "start",
          sortable: true,
          value: "campaign",
          dataType: "text",
        },
        {
          text: "Email",
          align: "start",
          sortable: true,
          value: "email",
          dataType: "text",
        },
        {
          text: "Status",
          align: "start",
          sortable: true,
          value: "response",
          dataType: "text",
        },
      ],
      mailer_log: [],
      headers: {
        false: [
          {
            text: "Grupo",
            align: "start",
            sortable: true,
            value: "group_name",
            dataType: "text",
          },
          {
            text: "Nombre",
            align: "start",
            sortable: true,
            value: "name",
            dataType: "text",
          },
          {
            text: "Email",
            align: "start",
            sortable: true,
            value: "email",
            dataType: "text",
            width: "30%",
          },
          {
            text: "Teléfono",
            align: "end",
            sortable: true,
            value: "mobile",
            dataType: "text",
          },
          {
            text: "Ciudad",
            align: "end",
            sortable: true,
            value: "city",
            dataType: "text",
          },
          {
            text: "Dep.",
            align: "end",
            sortable: true,
            value: "province",
            dataType: "text",
          },
        ],
        true: [
          {
            text: "Nombre",
            align: "start",
            sortable: true,
            value: "name",
            dataType: "text",
          },
          {
            text: "Teléfono",
            align: "end",
            sortable: true,
            value: "mobile",
            dataType: "text",
          },
        ],
      },
      issueDateFrom: getIssueDate(5),
      issueDateTo: getToday(),
      editcss: ".ql-align-center{text-align: center;}",
    };
  },
  methods: {
    PhotoToBase64() {
      if (!this.chosenPhoto) {
        this.data = "No File Chosen";
      }

      this.getBase64(this.chosenPhoto).then((data) => {
        var qry = {
          account: this.$store.state.profile.account,
          banner: data,
          filename: this.chosenPhoto.name,
        };
        console.log(qry);
        webserver("upload_banner", qry, (data) => {
          this.loading_status = false;
          this.msgheader = "https://mayorista.app/banners/" + data ;
          console.log(this.msgheader);
        });
        //this.broadcast.msg = "<img src='" + data + "'></img>";
      });
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    
    async msg_submit(e) {
      e.preventDefault();
      this.validMsg = this.$refs.msgForm.validate();
      if (this.validMsg) {
        var clie = [];
        for (clie of this.selected) {
          if (clie.email) {
            this.broadcast.account = this.$store.state.profile.account;
            this.broadcast.email = clie.email;
            this.broadcast.msg = "<html><head><style>"+ this.editcss +"</style></head><body style='background-color: #999;'><table><tr><td><img src='"+ this.msgheader +"'></td></tr><tr><td>"+ this.msgbody + "</td></tr></table></body></html>";
            this.broadcast.subject = clie.name + ", " + this.subject;
            console.log(this.broadcast);
            let promise = new Promise((resolve, reject) => {
              webserver(
                "send_email",
                this.broadcast,
                function (data) {
                  resolve(data);
                },
                function () {
                  reject([]);
                }
              );
            });
            let result = await promise;
            this.mailer_log.push(result[0]);
            console.log(result);
          }
        }
      }
    },
    newOrder(e) {
      this.$router.push({ name: "Inicio", params: e });
    },
    handleClick(data) {
      this.item = { ...data };
      this.newItem = false;
      this.dialog = true;
    },
    updateLocation(e) {
      this.store.location = JSON.stringify(e);
    },
    get_items() {
      var qry = {
        account: this.$store.state.profile.account,
        table: this.table,
        filters: [
          { field: "group_name", operator: " IN ", value: "'TIENDAS Y SUPERMERCADOS'" },
          // { field: "date(issue_date)", operator: ">=", value: this.issueDateFrom },
          // { field: "date(issue_date)", operator: "<=", value: this.issueDateTo },
        ],
      };
      this.loading_status = true;
      webserver("get_table_clients", qry, (data) => {
        this.loading_status = false;
        this.items = data;
        if (data.length > 0) {
          this.items = data;
        } else {
          this.newForm();
        }
      });
    },
    refresh() {
      this.dialog = false;
      this.get_items();
    },
    getImagePhoto: function (account, src) {
      var url = "https://mayorista.app/photos/" + account + "/" + src;
      if (this.compressed_image) url = this.compressed_image;
      return url;
    },
    newForm() {
      this.item = createItem();
      this.item.account = this.$store.state.profile.account;
      this.valid = false;
      this.newItem = true;
      this.dialog = true;
    },
    call_contact(data) {
      window.location.href = "tel://" + data;
    },
    SendEmails() {
      this.broadcast_dialog = false;
      console.log(this.selected);
    },
  },
};
</script>

<style lang="scss" scoped></style>
