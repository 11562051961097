<template>
  <v-container>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="800px"
      :scrollable="$vuetify.breakpoint.xsOnly"
      :fullscreen="$vuetify.breakpoint.xsOnly"
    >
      <v-form v-model="valid" ref="itemForm" @submit="item_submit">
        <v-card tile height="100%">
          <v-card-title class="pa-0">
            <v-app-bar>
              <v-tabs show-arrows v-model="tab">
                <v-tab key="contact">
                  <v-icon left> mdi-account </v-icon>
                </v-tab>
                <v-tab key="tax">
                  <v-icon left> mdi-bank-outline </v-icon>
                </v-tab>
                <v-tab key="photo">
                  <v-icon> mdi-camera-plus-outline </v-icon>
                </v-tab>
                <v-tab key="map">
                  <v-icon> mdi-map-search-outline </v-icon>
                </v-tab>
                <v-tab key="shipping">
                  <v-icon left> mdi-truck-fast-outline </v-icon>
                </v-tab>
              </v-tabs>
              <v-icon @click="close()"> mdi-close </v-icon>
            </v-app-bar>
          </v-card-title>
          <v-card-text class="pt-3">
            <v-tabs-items v-model="tab">
              <v-tab-item key="contact">
                <v-card tile>
                  <v-card-text>
                    <v-row>
                      <v-col cols="12" md="4">
                        <v-text-field
                          v-model="item.group_name"
                          :rules="f_required"
                          label="Grupo"
                          hide-details="auto"
                          required
                          dense
                          outlined
                          :disabled="!edit"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="8">
                        <v-text-field
                          v-model="item.name"
                          id="name"
                          :rules="f_required"
                          label="Nombre"
                          hide-details="auto"
                          required
                          dense
                          outlined
                          :disabled="!edit"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-text-field
                          v-model="item.mobile"
                          label="Celular"
                          hide-details="auto"
                          dense
                          type="number"
                          :rules="f_required"
                          outlined
                          append-icon="mdi-phone-outline"
                          @click:append="call_contact(item.mobile)"
                          :readonly="!edit"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-text-field
                          v-model="item.email"
                          label="Correo Electrónico"
                          hide-details="auto"
                          dense
                          outlined
                          :disabled="!edit"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="12">
                        <v-text-field
                          v-model="item.address"
                          :rules="f_required"
                          label="Dirección"
                          hide-details="auto"
                          required
                          dense
                          outlined
                          :disabled="!edit"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="6" md="4">
                        <v-combobox
                          v-model="item.country"
                          hide-details="auto"
                          label="País"
                          :items="countries"
                          :rules="f_required"
                          outlined
                          required
                          dense
                          @change="country_change"
                          :disabled="!edit"
                        />
                      </v-col>
                      <v-col cols="6" md="4">
                        <v-combobox
                          v-model="item.province"
                          hide-details="auto"
                          label="Provincia"
                          :items="state"
                          :rules="f_required"
                          outlined
                          required
                          dense
                          @change="province_change"
                          :disabled="!edit"
                        />
                      </v-col>
                      <v-col cols="12" md="4">
                        <v-combobox
                          v-model="item.city"
                          hide-details="auto"
                          label="Ciudad"
                          :items="city"
                          :rules="f_required"
                          outlined
                          required
                          dense
                          :disabled="!edit"
                        />
                      </v-col>
                      <v-col cols="6" md="6">
                        <v-text-field
                          v-model="item.url"
                          label="Pagina Web"
                          hide-details="auto"
                          dense
                          outlined
                          :disabled="!edit"
                        />
                      </v-col>
                      <v-col cols="6" md="6">
                        <v-select
                          v-model="item.status"
                          hide-details="auto"
                          :rules="f_required"
                          item-text="text"
                          item-value="value"
                          :items="active_lst"
                          label="Status"
                          outlined
                          dense
                        />
                      </v-col>
                      <v-col cols="12" md="12">
                        <span class="pb-3" v-html="item.note"></span>
                      </v-col>
                      <v-col cols="12" md="12">
                        <v-text-field
                          v-model="note"
                          label="Observaciones"
                          hide-details="auto"
                          outlined
                          append-icon="mdi-plus"
                          @click:append="addComent"
                        />
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-tab-item>
              <v-tab-item key="tax">
                <v-card tile>
                  <v-card-text>
                    <v-row>
                      <v-col cols="12" md="6">
                        <v-select
                          v-model="item.type_id"
                          :items="type_id"
                          label="Tipo de documento"
                          outlined
                          dense
                          hide-details="auto"
                        ></v-select>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-text-field
                          v-model="item.identification_id"
                          label="Numero de documento"
                          hide-details="auto"
                          dense
                          outlined
                          required
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="12">
                        <v-select
                          v-model="item.additional_accoun_id"
                          :items="typeaccountsID"
                          label="Régimen fiscal"
                          outlined
                          dense
                          hide-details="auto"
                        ></v-select>
                      </v-col>
                      <v-col cols="12" md="12">
                        <v-select
                          v-model="item.tax_level_code"
                          :items="TaxLevelCodes"
                          label="Regimen fiscal"
                          outlined
                          dense
                          hide-details="auto"
                        ></v-select>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-tab-item>
              <v-tab-item key="photo" align="center" justify="center">
                <v-img
                  cover
                  aspect-ratio="1"
                  class="grey lighten-2"
                  :src="productPhoto"
                  height="500"
                >
                  <v-fab-transition>
                    <v-btn
                      class="mx-2 btn-upload"
                      fab
                      light
                      small
                      color="secondary"
                      @click="chooseImage"
                    >
                      <v-icon dark> mdi-camera-plus-outline </v-icon>
                    </v-btn>
                  </v-fab-transition>
                  <v-btn
                    v-if="item.picture"
                    color="#075E54"
                    absolute
                    fab
                    small
                    dark
                    right
                    class="v-btn--example"
                    @click="openURL(getImagePhoto(item.account, item.code, item.picture))"
                  >
                    <v-icon>mdi-magnify</v-icon>
                  </v-btn>
                </v-img>
                <input
                  class="file-input"
                  ref="fileInput"
                  type="file"
                  @input="onSelectFile"
                />
              </v-tab-item>

              <v-tab-item key="map" class="pa-0">
                <div class="map">
                  <StoreMap
                    @store-location="updateLocation"
                    :storeLocation="item.location"
                    :recenter="recenter"
                  />
                </div>
              </v-tab-item>
              <v-tab-item key="shipping">
                <v-card tile>
                  <v-card-text>
                    <v-row>
                      <v-col cols="12" md="12">
                        <v-text-field
                          v-model="item.shipping_name"
                          id="shipping_name"
                          label="Nombre"
                          hide-details="auto"
                          dense
                          outlined
                          required
                          @keyup="returnCap"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="8">
                        <v-text-field
                          v-model="item.shipping_address"
                          id="shipping_address"
                          label="Dirección"
                          hide-details="auto"
                          dense
                          outlined
                          required
                          @keyup="returnCap"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="4">
                        <v-text-field
                          v-model="item.shipping_address2"
                          id="shipping_address2"
                          label="Local/Oficina/Unidad"
                          hide-details="auto"
                          dense
                          outlined
                          required
                          @keyup="returnCap"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" md="4">
                        <v-text-field
                          v-model="item.shipping_postal_code"
                          id="shipping_postal_code"
                          label="Código Postal"
                          hide-details="auto"
                          dense
                          outlined
                          required
                          @keyup="returnCap"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" md="4">
                        <v-text-field
                          v-model="item.shipping_city"
                          id="shipping_city"
                          label="Ciudad"
                          hide-details="auto"
                          dense
                          outlined
                          required
                          @keyup="returnCap"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="4">
                        <v-text-field
                          v-model="item.shipping_province"
                          id="shipping_province"
                          label="Provincia"
                          hide-details="auto"
                          dense
                          outlined
                          required
                          @keyup="returnCap"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="4">
                        <v-text-field
                          v-model="item.shipping_mobile"
                          label="Celular"
                          hide-details="auto"
                          dense
                          outlined
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" md="8">
                        <v-text-field
                          v-model="item.shipping_email"
                          label="Correo Electrónico"
                          hide-details="auto"
                          dense
                          outlined
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-tab-item>
              <v-tab-item key="menus" align="center" justify="center">
                <v-img
                  cover
                  aspect-ratio="1"
                  class="grey lighten-2"
                  :src="menuPhoto"
                  height="500"
                >
                  <v-fab-transition>
                    <v-btn
                      class="mx-2 btn-upload"
                      fab
                      light
                      small
                      color="secondary"
                      @click="chooseMenu"
                    >
                      <v-icon dark> mdi-camera-plus-outline </v-icon>
                    </v-btn>
                  </v-fab-transition>
                  <v-btn
                    v-if="item.menus"
                    color="#075E54"
                    absolute
                    fab
                    small
                    dark
                    right
                    class="v-btn--example"
                    @click="openURL(getImageMenu(item.account, item.code, item.menus))"
                  >
                    <v-icon>mdi-magnify</v-icon>
                  </v-btn>
                </v-img>
                <input
                  class="file-input"
                  ref="menuInput"
                  type="file"
                  @input="onSelectMenu"
                />
              </v-tab-item>
            </v-tabs-items>
          </v-card-text>
          <v-card-actions class="pa-0">
            <v-bottom-navigation grow>
              <v-btn @click="delete_itm(item)" :disabled="newItem">
                <span>Borrar</span>
                <v-icon>mdi-delete-outline</v-icon>
              </v-btn>
              <v-btn @click="edit = !edit">
                <span>Editar</span>
                <v-icon>mdi-pencil-outline</v-icon>
              </v-btn>

              <v-btn :disabled="!valid" type="submit">
                <span>Guardar</span>
                <v-icon>mdi-content-save-outline</v-icon>
              </v-btn>
              <v-btn @click="newOrder">
                <span>Compra</span>
                <v-icon>mdi-cart-outline</v-icon>
              </v-btn>
            </v-bottom-navigation>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>

    <v-dialog
      v-model="QRdialog"
      persistent
      max-width="600px"
      :scrollable="$vuetify.breakpoint.xsOnly"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      light
    >
      <v-card tile height="100%">
        <v-card-title class="pa-0">
          <v-app-bar>
            <v-spacer></v-spacer>
            <v-btn x-small color="danger" fab @click="QRdialog = !QRdialog">
              <v-icon> mdi-close </v-icon>
            </v-btn>
          </v-app-bar>
        </v-card-title>
        <v-card-text class="pa-0">
          <StreamBarcodeReader
            @decode="onDecode"
            @loaded="onLoaded"
            :stopCamera="stopCamera"
          />
        </v-card-text>
        <v-card-text class="pa-6">
          <v-row class="fill-height" align="center" justify="center">
            {{ this.result }}
          </v-row>
        </v-card-text>
        <v-card-actions> </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog persistent max-width="290" v-model="dialog_confirm">
      <v-card>
        <v-card-title> Confirmar </v-card-title>
        <v-card-text> Desea eliminar el registro? </v-card-text>
        <v-card-actions>
          <v-btn text color="red" type="button" @click="dialog_confirm = !dialog_confirm">
            Cancelar
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn text color="success" type="button" @click="delete_item">
            Continuar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { webserver } from "../services/webserver.js";
import createItem from "../utils/createParty.js";
import cities_lst from "../utils/cities.js";
import StoreMap from "../components/Location.vue";
import { StreamBarcodeReader } from "../utils/barcodereader.js";
const HEREKEY = "s1hwrrji1zDvsXhdBgZTz7JJKoug3cDXso1zrQZ3nWU";
export default {
  name: "PartyForm",
  components: { StoreMap, StreamBarcodeReader },
  data() {
    return {
      edit: false,
      menuPhoto: null,
      QRdialog: false,
      stopCamera: false,
      initCamera: false,
      result: "",
      productPhoto: null,
      viewMap: false,
      recenter: false,
      ipgeo: null,
      cities: cities_lst(),
      countries: [],
      state: [],
      city: [],
      loading_status: false,
      tab: null,
      dialog_confirm: false,
      compressed_image: null,
      compressed_menu: null,
      reg_delete: null,
      valid: false,
      active_lst: [
        { value: "1", text: "Activo" },
        { value: "0", text: "InActivo" },
      ],
      f_required: [(v) => !!v || "Requerido"],
      f_email: [
        (v) => !!v || "Correo valido",
        (v) => /.+@.+/.test(v) || "Correo no valido",
      ],
      f_email_val: [
        (v) =>
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || "Correo no valido",
      ],
      codeRules: [
        (v) => !!v || "El código es requerido",
        (v) => v.length === 4 || "EL código debe tener 4 caracteres.",
      ],
      type_id: [
        { value: "13", text: "Cédula de ciudadanía" },
        { value: "31", text: "NIT" },
        { value: "11", text: "Registro civil" },
        { value: "12", text: "Tarjeta de identidad" },
        { value: "21", text: "Tarjeta de extranjería" },
        { value: "22", text: "Cédula de extranjería" },
        { value: "41", text: "Pasaporte" },
        { value: "42", text: "Documento de identificación extranjero" },
        { value: "50", text: "NIT de otro país" },
      ],
      typeaccountsID: [
        { value: "1", text: "Persona Juridica" },
        { value: "2", text: "Persona Natural" },
      ],
      TaxLevelCodes: [
        { value: "04", text: "Simple" },
        { value: "05", text: "Ordinario" },
      ],
      stloc: null,
      note: null,
    };
  },
  props: {
    table: {
      type: String,
      required: true,
    },
    item: {
      type: Object,
      required: true,
      default: createItem(),
    },
    groups: {
      type: Array,
      required: false,
    },
    dialog: {
      type: Boolean,
      required: true,
      default: false,
    },
    newItem: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  mounted() {
    // var dta = this.cities;
    // console.log(dta);
    // const keys = Object.keys(dta).sort();
    // this.countries = keys;
    // var stt = this.cities[this.countries[0]];
    // this.state = Object.keys(stt).sort();
    // this.citi=this.cities[this.countries[0]][this.state[0]].sort();
    // this.edit = false;
  },
  methods: {
    addComent() {
      var currentdate = new Date();
      var datetime =
        currentdate.getDate() +
        "/" +
        (currentdate.getMonth() + 1) +
        "/" +
        currentdate.getFullYear() +
        " @ " +
        currentdate.getHours() +
        ":" +
        currentdate.getMinutes() +
        ":" +
        currentdate.getSeconds();

      this.item.note = this.item.note + datetime + ": " + this.note + "<br>";
    },
    openURL(e) {
      window.open(e, "", "width=400,height=800");
    },
    re_center() {
      this.recenter = !this.recenter;
    },
    acceptNumber(e) {
      console.log(e);
    },
    returnCap(event) {
      this.item[event.target.id] = event.target.value.toUpperCase();
    },
    onlyNumber($event) {
      var keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
        $event.preventDefault();
      }
    },
    getImagePhoto: function (account, code, src) {
      var url = "https://mayorista.app/photos/" + account + "/" + code + "/" + src;
      if (this.compressed_image) url = this.compressed_image;
      return url;
    },
    getImageMenu: function (account, code, src) {
      var url = "https://mayorista.app/photos/" + account + "/" + code + "/" + src;
      if (this.compressed_menu) url = this.compressed_menu;
      return url;
    },
    chooseImage() {
      this.$refs.fileInput.click();
    },
    chooseMenu() {
      this.$refs.menuInput.click();
    },
    onSelectFile() {
      const input = this.$refs.fileInput;
      const files = input.files;
      if (files && files[0]) {
        const reader = new FileReader();
        reader.onload = (e) => {
          var img = new Image();
          img.onload = () => {
            var prct = 800 / img.width;
            const elem = document.createElement("canvas");
            elem.width = img.width * prct;
            elem.height = img.height * prct;
            const ctx = elem.getContext("2d");
            ctx.drawImage(img, 0, 0, img.width * prct, img.height * prct);
            var imageUrl = elem.toDataURL("image/jpeg");
            this.compressed_image = imageUrl;
            this.productPhoto = imageUrl;
            //this.$emit("compressed-image", imageUrl);
          };
          img.src = e.target.result;
        };
        reader.readAsDataURL(files[0]);
        this.item.picture = files[0].name.toLowerCase();
        console.log(">>>>", this.item);
        //this.$emit("input", files[0].name.toLowerCase());
      }
    },
    onSelectMenu() {
      const input = this.$refs.menuInput;
      const files = input.files;
      if (files && files[0]) {
        const reader = new FileReader();
        reader.onload = (e) => {
          var img = new Image();
          img.onload = () => {
            var prct = 800 / img.width;
            const elem = document.createElement("canvas");
            elem.width = img.width * prct;
            elem.height = img.height * prct;
            const ctx = elem.getContext("2d");
            ctx.drawImage(img, 0, 0, img.width * prct, img.height * prct);
            var imageUrl = elem.toDataURL("image/jpeg");
            this.compressed_menu = imageUrl;
            this.menuPhoto = imageUrl;
            //this.$emit("compressed-image", imageUrl);
          };
          img.src = e.target.result;
        };
        reader.readAsDataURL(files[0]);
        this.item.menus = files[0].name.toLowerCase();
        //this.$emit("input", files[0].name.toLowerCase());
      }
    },
    item_submit(e) {
      e.preventDefault();
      this.valid = this.$refs.itemForm.validate();
      if (this.valid) {
        this.getLocation().then((e) => {
          this.item.account = this.$store.state.profile.account;

          if (this.item.geodata === null) {
            console.log(this.item.geodata);
            this.item.geodata = JSON.stringify(e);
            this.item.agent = this.$store.state.profile.name;
          }
          var qry = {
            account: this.$store.state.profile.account,
            table: this.table,
            data: [this.item],
          };
          if (this.compressed_image) {
            qry.photo = this.compressed_image;
          }
          if (this.compressed_menu) {
            qry.menu = this.compressed_menu;
          }
          console.log(qry);
          webserver("put_table", qry, (data) => {
            console.log(data);
            this.compressed_image = null;
            this.loading_status = false;
            this.$emit("refresh", true);
          });
        });
      }
    },
    delete_itm(data) {
      this.reg_delete = data;
      this.dialog_confirm = true;
    },
    delete_item() {
      //var record = this.reg_delete;
      this.loading_status = true;
      var qry = {
        table: this.table,
        filters: {
          account: this.reg_delete.account,
          code: this.reg_delete.code,
        },
      };
      webserver("delete_record", qry, () => {
        this.reg_delete = null;
        this.dialog_confirm = false;
        this.loading_status = false;
        this.$emit("refresh", true);
      });
    },
    close() {
      this.$emit("close", false);
    },
    call_contact(data) {
      window.location.href = "tel://" + data;
    },
    country_change(e) {
      this.state = [];
      this.city = [];
      this.item.province = null;
      this.item.city = null;
      var stt = this.cities[e];
      this.state = Object.keys(stt).sort();
    },
    province_change(e) {
      this.city = [];
      this.item.city = null;
      this.city = this.cities[this.item.country][e].sort();
    },
    updateLocation(e) {
      this.item.location = JSON.stringify(e);
    },
    onDecode(e) {
      this.stopCamera = true;
      this.QRdialog = false;
      this.item.qr = e;
    },
    openQR() {
      this.stopCamera = false;
      this.QRdialog = true;
    },
    onLoaded(e) {
      console.log(e);
    },
    getLocation() {
      return new Promise((resolve, reject) => {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(
            (res) => {
              resolve([res.coords.latitude, res.coords.longitude]);
            },
            (err) => {
              console.log(err);
              reject(err);
            }
          );
        } else {
          reject("No Geolocation support.");
        }
      });
    },
    searchSugestions(event) {
      var toSearch = event.target.value;
      if (toSearch.length > 5) {
        var remainder = toSearch.length % 5;
        if (remainder == 0) {
          var url =
            "https://places.cit.api.here.com/places/v1/autosuggest?at=10.39972, -75.51444&q=" +
            encodeURIComponent(toSearch) +
            "&app_id=ztAJEoWoyoYELynZbZdo&app_code=" +
            HEREKEY;
          console.log(url);
          var xhr = new XMLHttpRequest();
          xhr.onreadystatechange = function () {
            console.log(xhr);
            if (xhr.readyState === 4) {
              console.log(xhr);
            }
          };

          xhr.open("GET", url, true);
          xhr.send("");
          console.log(remainder);
        } else {
          console.log("no");
        }
      }
    },
    newOrder() {
      this.$emit("newOrder", this.item);
    },
  },
  watch: {
    item: function (e) {
      if (e.picture) {
        this.productPhoto = this.getImagePhoto(e.account, e.code, e.picture);
      } else {
        this.productPhoto = null;
      }
      if (e.menus) {
        this.menuPhoto = this.getImageMenu(e.account, e.code, e.menus);
      } else {
        this.menuPhoto = null;
      }
      if (this.newItem) {
        this.edit = true;
        this.item.country = window.company.country;
        this.item.province = window.company.province;
        this.item.city = window.company.city;
      }
      if (this.item.country) {
        const keys = Object.keys(this.cities).sort();
        this.countries = keys;
        var stt = this.cities[this.item.country];
        this.state = Object.keys(stt).sort();
        // this.citi=this.cities[this.item.country][this.item.state].sort();
      }
    },
  },
};
</script>

<style>
.v-btn--example {
  top: 10px;
  position: absolute;
  margin: 0 0 0 0;
}

.base-image-input {
  display: block;
  width: 100%;
  height: 250px;
  cursor: pointer;
  background-size: cover;
  background-position: center center;
}

.placeholder {
  background: #f0f0f0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #333;
  font-size: 18px;
  font-family: Helvetica;
}

.placeholder:hover {
  background: #e0e0e0;
}

.file-input {
  display: none;
}

.map {
  height: 400px;
}

.btn-upload {
  top: 150px;
}
</style>
